
import { useDelivery } from '@/composables/useDelivery'
import { useRouter } from 'vue-router'
import { defineComponent, onMounted } from 'vue'
export default defineComponent({
    setup() {
        const {
            deliveryList,
            fetchDeliveryListManager,
            fetchDeliveryStages,
            setActiveDeliveryStage,
            activeDeliveryStage,
            deliveryStages,
            isLoading,
        } = useDelivery()
        const router = useRouter()
        const changeSelectedDeliveryStage = (event: Event) => {
            const selectedCarWashId = parseInt((event.target as HTMLInputElement).value)
            setActiveDeliveryStage(selectedCarWashId)
            fetchDeliveryListManager()
        }

        onMounted(() => {
            fetchDeliveryListManager()
            fetchDeliveryStages()
        })
        return {
            isLoading,
            deliveryList,
            router,
            deliveryStages,
            changeSelectedDeliveryStage,
            activeDeliveryStage,
        }
    },
})
